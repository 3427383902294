import("./src/global.scss");

{/* <script type="application/ld+json">
{
    "@context": "http://schema.org",
    "@type": "Organization",
    "name": "SEO PowerSuite",
    "description": "SEO PowerSuite is a software company that develops all-in-one SEO tools to optimize sites & grow search traffic: Rank Tracker, WebSite Auditor, SEO SpyGlass, LinkAssistant.",
    "url": "https://link-assistant.com",
    "logo": "https://cdn1.link-assistant.com/images/press/new-press/logos/sps-vertical.png",
    "email": "support@link-assistant.com",
    "address": {
                    "@type": "PostalAddress",
                    "addressCountry": "CY",
                    "postalCode": "1100",
                    "streetAddress": "21-23 Louki Akrita"
    },
    "founder": {
                    "@type": "Person",
                    "name": "Aleh Barysevich",
                    "gender": "Male",
                    "jobTitle": "CMO",
                    "image": "https://cdn.link-assistant.com/images/team/aleh-barysevich.jpg",
                    "sameAs": [
                        "https://twitter.com/ab80",
                        "https://www.linkedin.com/in/alehbarysevich/"
        ]      
    },
    "foundingDate": "2005-02-07",
    "sameAs" : [
        "https://www.crunchbase.com/organization/link-assistant-com",
        "https://www.facebook.com/linkassistant/",
        "https://www.linkedin.com/company/seopowersuite/",
        "https://twitter.com/seopowersuite",
        "https://www.youtube.com/user/LinkAssistantVideos"
    ],
    "contactPoint" : [
        {
            "@type" : "ContactPoint",
            "contactType" : "customer service",
            "email": "support@link-assistant.com",
            "url": "https://link-assistant.com"
        }
    ]
}
</script> */}